var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "height": "".concat(_vm.height, "px") || '16px',
      "width": "".concat(_vm.width, "px") || '16px',
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M11 1.5H5C4.60218 1.5 4.22064 1.65804 3.93934 1.93934C3.65804 2.22064 3.5 2.60218 3.5 3V14.5L8 10.5L12.5 14.5V3C12.5 2.60218 12.342 2.22064 12.0607 1.93934C11.7794 1.65804 11.3978 1.5 11 1.5Z",
      "fill": _vm.color
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }